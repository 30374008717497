import { Injectable } from '@angular/core';
import { CanActivate, GuardResult, RedirectCommand, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, switchMap } from 'rxjs';
import * as fromRoot from '../reducers';

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(
        private store: Store,
        private router: Router
    ) {}
    canActivate(route, state): Observable<GuardResult> {
        const tenantAccount$ = this.store.select(fromRoot.selectTenantAccount);
        const userIsAdmin$ = this.store.select(fromRoot.selectUserIsAdmin);
        return tenantAccount$.pipe(
            filter((tenant) => tenant !== null),
            switchMap(() => userIsAdmin$),
            map((isAdmin) => {
                if (isAdmin) {
                    return true;
                }
                return new RedirectCommand(this.router.parseUrl('/directory'), { skipLocationChange: false });
            })
        );
    }
}
