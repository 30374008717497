import { DirectoryUser } from 'src/app/data/data.models';
import { SingleUserCheck, CheckResult } from '../integrity.models';

export class ReportsToSelfCheck extends SingleUserCheck {
    static override checkName = 'ReportsToSelfCheck';
    constructor() {
        super('managerId');
    }
    runSingle(user: DirectoryUser): CheckResult | null {
        if (user.managerId && user.managerId === user.id) {
            return {
                checkName: 'ReportsToSelfCheck',
                userId: user.id,
                message: 'User reports to self',
                checkIndex: -1,
                isFixed: false,
                isModified: false
            };
        }
        return null;
    }

    override getDisplayName(): string {
        return 'Reports to Self';
    }

    override getDescription(): string {
        return 'Check if a user reports to themselves';
    }
}
