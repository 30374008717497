import { DirectoryUser } from 'src/app/data/data.models';
import { CheckResult, SingleUserCheck } from '../integrity.models';

export class EmptyValuesCheck extends SingleUserCheck {
    static override checkName = 'EmptyValuesCheck';
    runSingle(user: DirectoryUser): CheckResult | null {
        if (!user[this.field]) {
            return {
                checkName: 'EmptyValuesCheck',
                userId: user.id,
                message: `Empty value for field ${this.field}`,
                checkIndex: -1, // This will be set correctly in the Report class,
                isFixed: false,
                isModified: false
            };
        }
        return null;
    }

    override getField(): string {
        return this.field;
    }

    override getDisplayName(): string {
        return `Empty values of ${this.field}`;
    }

    override getDescription(): string {
        return `Check if users have empty values in ${this.field}.`;
    }
}
