import { Component, Inject, Input, OnDestroy, output } from '@angular/core';
import { Feature, FeatureDefinition, FeatureDefinitions } from './features.models';
import { catchError, EMPTY, Subject, Subscription, take, takeUntil, throwError, withLatestFrom } from 'rxjs';
import * as microsoftTeams from '@microsoft/teams-js';
import { MSAL_GUARD_CONFIG, MsalGuardAuthRequest, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { FeatureService } from '../../services/feature.service';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as dataActions from '../../../data/data.actions';
import * as authActions from '../../../auth/auth.actions';
import { ModifiedAccountInfo } from 'src/app/auth/auth.models';
import { NgClass, NgIf } from '@angular/common';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'dir-feature-consent-button',
    templateUrl: './feature-consent-button.component.html',
    styleUrl: './feature-consent-button.component.scss',
    imports: [NgClass, TooltipDirective, NgIf]
})
export class FeatureConsentButtonComponent implements OnDestroy {
    @Input() set feature(value: Feature) {
        this._feature = value;
        this.definition = FeatureDefinitions[value];
        this.hasConsentSubscription.unsubscribe();
        this.hasConsentSubscription = this.featureService.hasFeature(value).subscribe((hasConsent) => {
            this.hasConsent = hasConsent;
        });
    }
    get feature(): Feature {
        return this._feature;
    }
    private _feature: Feature = Feature.Directory;
    @Input() classes = 'btn-warning';
    definition: FeatureDefinition = FeatureDefinitions[this.feature];
    hasConsentSubscription = Subscription.EMPTY;

    user$ = this.store.pipe(select(fromRoot.selectUser));
    isTeams$ = this.store.pipe(select(fromRoot.selectIsTeams));
    featureConsentResult = output<boolean>();
    hasConsent = false;
    isError = false;
    errorMessage = '';
    private unsubscribe$ = new Subject<void>();

    constructor(
        private store: Store,
        private msalService: MsalService,
        private featureService: FeatureService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
    ) {}

    private consentBrowser(user: ModifiedAccountInfo) {
        this.msalService
            .acquireTokenPopup({
                scopes: [...this.definition.scopes],
                loginHint: user.username,
                state: 'AddScopes',
                domainHint: user.tenantId
            })
            .pipe(
                take(1),
                catchError((error) => {
                    if (error) {
                        if (error.toString() == 'BrowserAuthError: user_cancelled: User cancelled the flow.') {
                            this.cancelledConsentMessage();
                            return EMPTY;
                        }
                    }
                    return throwError(() => new Error(error));
                })
            )
            .subscribe((result) => {
                if (result.accessToken) {
                    if (
                        result.scopes &&
                        result.scopes.length > 0 &&
                        this.definition.scopes.every((scope) =>
                            result.scopes.some((s) => s.toLowerCase() === scope.toLowerCase())
                        )
                    ) {
                        this.postConsentSuccess();
                    } else {
                        this.postConsentFail();
                    }
                    this.featureService.refreshAvailableScopes();
                }
            });
    }

    private consentTeams(user: ModifiedAccountInfo) {
        const request: MsalGuardAuthRequest = { ...this.msalGuardConfig.authRequest };
        request.scopes = [...new Set([...request.scopes, ...this.definition.scopes])];
        microsoftTeams.app.initialize().then(() => {
            console.log('teams api initialized in feature-consent.component.ts');
            this.store.dispatch(authActions.startTeamsInteraction());
            microsoftTeams.authentication
                .authenticate({
                    url: window.location.origin + '/auth-teams-scopes?scopes=' + request.scopes.join(','),
                    height: 640
                })
                .then(
                    (result) => {
                        console.log('success, reloading');
                        this.store.dispatch(authActions.endTeamsInteraction());
                        this.featureService
                            .refreshAvailableScopesAsObservable()
                            .pipe(take(1))
                            .subscribe((scopes) => {
                                this.featureService.availableFeatures$.pipe(take(1)).subscribe((features) => {
                                    if (features.includes(this.feature)) {
                                        this.postConsentSuccess();
                                    } else {
                                        this.postConsentFail();
                                    }
                                });
                            });
                    },
                    (reason) => {
                        console.log('INTERACTION FAILED IN APP.COMPONENT.TS');
                        console.log('failure', reason);
                        console.log(JSON.stringify(reason));
                        this.store.dispatch(authActions.endTeamsInteraction());
                        if (reason.message === 'consent_required' || reason.message === 'CancelledByUser') {
                            this.cancelledConsentMessage();
                            this.featureService.refreshAvailableScopes();
                        } else {
                            this.isError = true;
                            this.errorMessage = 'Error Providing Consent: ' + reason.message;
                        }
                    }
                );
        });
    }

    onClick($event: MouseEvent) {
        this.user$
            .pipe(takeUntil(this.unsubscribe$), withLatestFrom(this.isTeams$), take(1))
            .subscribe(([user, isTeams]) => {
                if (user) {
                    if (isTeams) {
                        this.consentTeams(user);
                    } else {
                        this.consentBrowser(user);
                    }
                } else {
                    console.error('user is null');
                }
            });
        $event.preventDefault();
        return false;
    }

    private cancelledConsentMessage() {
        this.isError = true;
        this.errorMessage = 'Consent is required to use this feature.';
        this.featureConsentResult.emit(false);
    }

    private postConsentFail() {
        console.log("You don't have the feature - we need to do something about that");
        this.isError = true;
        this.errorMessage = 'Could not get consent this time.';
        this.featureConsentResult.emit(false);
    }

    private postConsentSuccess() {
        console.log('Well done - you have consented to the feature');
        this.isError = false;
        this.errorMessage = '';
        this.store.dispatch(authActions.setIsDemo({ isDemo: false }));
        this.store.dispatch(dataActions.reloadUsersAfterConsent());
        this.featureConsentResult.emit(true);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
