import { DirectoryUser } from 'src/app/data/data.models';
import { AllUsersCheck, CheckResult } from '../integrity.models';

export class DuplicateValuesCheck extends AllUsersCheck {
    static override checkName = 'DuplicateValuesCheck';
    constructor(fieldsToCheck: keyof DirectoryUser) {
        super(fieldsToCheck);
    }

    run(users: DirectoryUser[]): CheckResult[] {
        const results: CheckResult[] = [];
        const valueMap: Record<string, string[]> = {};

        for (const user of users) {
            const fieldValue = user[this.field] as string;
            if (fieldValue) {
                if (!valueMap[fieldValue]) {
                    valueMap[fieldValue] = [];
                }
                valueMap[fieldValue].push(user.id);
            }
        }

        for (const [value, ids] of Object.entries(valueMap)) {
            if (ids.length > 1) {
                ids.forEach((id) => {
                    results.push({
                        checkName: 'DuplicateValuesCheck',
                        userId: id,
                        message: `Duplicate value for field ${this.field}: ${value}`,
                        checkIndex: -1,
                        isFixed: false,
                        isModified: false
                    });
                });
            }
        }

        return results;
    }

    override getDisplayName(): string {
        return `Duplicate values of ${this.field}`;
    }

    override getDescription(): string {
        return `Check if users have identical values in ${this.field}.`;
    }
}
